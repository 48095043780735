// Main JS entry point (no code here, only import)

/** import layouts files */
import "./layouts/header";
import "./layouts/vision-swiper";
import "./layouts/partners-swiper";
import "./layouts/construction_swiper";

/** import blogpage files */
import "./blog/filter-posts";
import "./blog/lazy-loading";
