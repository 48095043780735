//swiper 1

const logoSwiper1 = document.querySelector('#partner-swiper1');

if(logoSwiper1) {
  // @ts-ignore
  new Swiper(logoSwiper1, {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflow: {
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 2000,
    },
  });
}

//swiper 2

const logoSwiper2 = document.querySelector('#partner-swiper2');

if(logoSwiper2) {
  // @ts-ignore
  new Swiper(logoSwiper2, {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflow: {
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 2000,
    },
  });
}
