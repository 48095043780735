const categoriesFilters = document.querySelectorAll<HTMLElement>('.blog-filter_posts_wrapper .blog-categories_filter_container .category_name');
const allPosts = document.querySelectorAll<HTMLElement>('.blog-posts_list_container .blog-post_card');
const categoryBurgerMenuMobile = document.querySelector('.mobile-categories-filter_container') as HTMLElement;
const categoriesMenu = document.querySelector('.blog-categories_filter_container') as HTMLElement;
const showAllPostsBtn = document.querySelector('#show_all_posts') as HTMLElement;
const chosenCategoryMenu = document.querySelector('.chosen_category_text') as HTMLElement;
const otherPostsCards = document.querySelectorAll<HTMLElement>('.other_posts_wrapper .blog-post_card');
const otherPostsMore = document.querySelector('#show_other_more_posts')  as HTMLElement;;

const MAX_POST_SHOW_PER_PAGE = 9;

const showOnlySomePosts = () => {
  let counter = 0;
  allPosts && allPosts.forEach(post => {
    counter++;
    if (counter <= MAX_POST_SHOW_PER_PAGE) {
      post.classList.add('active');
    }
  });
};

window.addEventListener('load', showOnlySomePosts);

let showMoreArticles = false;

const showAllPosts = () => {
  showMoreArticles = !showMoreArticles;

  if (allPosts) {
    for (let i = 0; i < allPosts.length ; i++) {
      let post = allPosts[i];

      if(i >= MAX_POST_SHOW_PER_PAGE && !showMoreArticles) {
        post.classList.remove('active');
      }

      if (i >= MAX_POST_SHOW_PER_PAGE && showMoreArticles) {
        post.classList.add('active');
      }
      
      
      if (showMoreArticles) {
        showAllPostsBtn.innerHTML = 'Afficher moins';
      } else {
        showAllPostsBtn.innerHTML = 'Afficher plus';
      }
    }
  }
};

showAllPostsBtn && showAllPostsBtn.addEventListener('click', showAllPosts);

const filterPostsByCategory = (event) => {

  allPosts && allPosts.forEach(post => {
    categoriesFilters && categoriesFilters.forEach(cat => cat.classList.remove('active-cat'));
    event.currentTarget.classList.add('active-cat');
    if (chosenCategoryMenu) {
      chosenCategoryMenu.innerHTML = event.currentTarget.innerText;
    }
    if (event.currentTarget.innerText.toLowerCase() === 'tous') {
      post.classList.add('active');
      showAllPostsBtn.classList.remove('hidden');
    } else if (post.dataset.category === event.currentTarget.innerText){
      post.classList.add('active');
      showAllPostsBtn.classList.add('hidden');
    } else {
      post.classList.remove('active');
    }
  });

  categoriesMenu && categoriesMenu.classList.remove('show');
};

categoriesFilters && categoriesFilters.forEach(cat => {
  if (cat.innerText.toLowerCase() === 'tous') {
    cat.classList.add('active-cat');
}});

categoriesFilters && categoriesFilters.forEach(singleCat => singleCat.addEventListener('click', filterPostsByCategory));

const openCategoriesMobile = () => {
  categoriesMenu && categoriesMenu.classList.toggle('show');
};

categoryBurgerMenuMobile && categoryBurgerMenuMobile.addEventListener('click', openCategoriesMobile);

let postCounter = 0;
otherPostsCards && otherPostsCards.forEach(post => {
  postCounter++;
  if (postCounter < 3) {
    post.classList.add('active');
  }
} );

let showmorePostsState = false;

const showOtherPostsMore = () => {
  let morePostsCounter = 0;
  showmorePostsState= !showmorePostsState;

  otherPostsCards && otherPostsCards.forEach(post => {
    morePostsCounter++;

    if (morePostsCounter > 2) {
      console.log(morePostsCounter)
      if (showmorePostsState) {
        post.classList.add('active');
      } else {
        post.classList.remove('active');
      }
    }
  });

  if (showmorePostsState) {
    otherPostsMore.innerHTML = 'Afficher moins';
  } else {
    otherPostsMore.innerHTML = 'Afficher plus';
  }
}

otherPostsMore && otherPostsMore.addEventListener('click', showOtherPostsMore);
