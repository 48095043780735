const headerElement = document.querySelector('#header') as HTMLElement;
const burgerMenuIcon = document.querySelector('#burger_image_header') as HTMLElement;
const hiderBurgerMenuIcon = document.querySelector('#burger-header_close_icon') as HTMLElement;
const burgerMenuContainer = document.querySelector('#burger_home_container') as HTMLElement;
const burgerMenuList = document.querySelector('#menu-nav-list') as HTMLElement;
const linkedinIcon = document.querySelector('#linkedin_img') as HTMLElement;
const twitterIcon = document.querySelector('#twitter_img') as HTMLElement;

const removeHeaderFilter = () => {
  if (document.documentElement.scrollTop === 0 && headerElement) {
    headerElement.classList.remove('header-filter_blur');
  } else {
    headerElement.classList.add('header-filter_blur');
  }
};

window.addEventListener('scroll', removeHeaderFilter);

const hideBurgerMenuIcon = () => {
  burgerMenuIcon.classList.add('hidden');
  hiderBurgerMenuIcon.classList.remove('hidden');
  burgerMenuContainer.classList.add('trans-visible');
};

const showBurgerMenuIcon = () => {
  hiderBurgerMenuIcon.classList.add('hidden');
  burgerMenuIcon.classList.remove('hidden');
  burgerMenuContainer.classList.remove('trans-visible');
};

burgerMenuIcon.addEventListener('click', hideBurgerMenuIcon);
burgerMenuList.addEventListener('click', showBurgerMenuIcon);
hiderBurgerMenuIcon.addEventListener('click', showBurgerMenuIcon);

const changeBgIcon = () => {
  twitterIcon && twitterIcon?.classList.add('icon_hover');
};

const removeBgIcon = () => {
  twitterIcon && twitterIcon?.classList.remove('icon_hover');
};

linkedinIcon && linkedinIcon.addEventListener('mouseover', changeBgIcon);
linkedinIcon && linkedinIcon.addEventListener('mouseout', removeBgIcon);