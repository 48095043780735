//swiper

const imgSwiper = document.querySelector("#vision-swiper");

if (imgSwiper) {
  // @ts-ignore
  new Swiper(imgSwiper, {
    grabCursor: false,
    centeredSlides: true,
    slidesPerView: 1,
    direction: "horizontal",
    loop: true,
    // autoplay: {
    //   delay: 2000,
    // },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    pagination: {
      el: ".swiper-pagination",
    },
  });
}
